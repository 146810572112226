// Body
$body-bg: #FFFFFF;

// Typography
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$utilities: (
  "rounded": (
    property: border-radius,
    class: rounded,
    responsive: true,
    values: (
      none: 0,
      1: .25rem,
      2: .375rem,
      3: .5rem,
      4: 1rem,
      5: 2rem,
      6: 3rem,
      7: 4rem,
      8: 5rem,
      9: 6rem,
      10: 7rem,
      circle: 50%,
      pill: var(--bs-border-radius-pill)
    )
  ),
  "rounded-top": (
    property: border-top-left-radius border-top-right-radius,
    class: rounded-top,
    responsive: true,
    values: (
      none: 0,
      1: .25rem,
      2: .375rem,
      3: .5rem,
      4: 1rem,
      5: 2rem,
      6: 3rem,
      7: 4rem,
      8: 5rem,
      9: 6rem,
      10: 7rem,
      circle: 50%,
      pill: var(--bs-border-radius-pill)
    )
  ),
  "rounded-end": (
    property: border-top-right-radius border-bottom-right-radius,
    class: rounded-end,
    responsive: true,
    values: (
      none: 0,
      1: .25rem,
      2: .375rem,
      3: .5rem,
      4: 1rem,
      5: 2rem,
      6: 3rem,
      7: 4rem,
      8: 5rem,
      9: 6rem,
      10: 7rem,
      circle: 50%,
      pill: var(--bs-border-radius-pill)
    )
  ),
  "rounded-bottom": (
    property: border-bottom-right-radius border-bottom-left-radius,
    class: rounded-bottom,
    responsive: true,
    values: (
      none: 0,
      1: .25rem,
      2: .375rem,
      3: .5rem,
      4: 1rem,
      5: 2rem,
      6: 3rem,
      7: 4rem,
      8: 5rem,
      9: 6rem,
      10: 7rem,
      circle: 50%,
      pill: var(--bs-border-radius-pill)
    )
  ),
  "rounded-start": (
    property: border-bottom-left-radius border-top-left-radius,
    class: rounded-start,
    responsive: true,
    values: (
      none: 0,
      1: .25rem,
      2: .375rem,
      3: .5rem,
      4: 1rem,
      5: 2rem,
      6: 3rem,
      7: 4rem,
      8: 5rem,
      9: 6rem,
      10: 7rem,
      circle: 50%,
      pill: var(--bs-border-radius-pill)
    )
  ),
  "text": (
    property: font-size,
    class: text,
    responsive: true,
    values: (
      8: 8px,
      9: 9px,
      10: 10px,
      11: 11px,
      12: 12px,
      13: 13px,
      14: 14px,
      15: 15px,
      16: 16px,
      17: 17px,
      18: 18px,
      19: 19px,
      20: 20px,
      21: 21px,
      22: 22px,
      23: 23px,
      24: 24px,
      25: 25px,
      26: 26px,
      27: 27px,
      28: 28px,
      29: 29px,
      30: 30px,
      31: 31px,
      32: 32px,
      33: 33px,
      34: 34px,
      35: 35px,
      36: 36px,
      37: 37px,
      38: 38px,
      39: 39px,
      40: 40px,
      41: 41px,
      42: 42px,
      43: 43px,
      44: 44px,
      45: 45px,
      46: 46px,
      47: 47px,
      48: 48px,
      49: 49px,
      50: 50px,
    )
  ),
  "my-w": (
    property: width,
    class: my-w,
    responsive: true,
    values: (
      0: 0,
      25: 25%,
      30: 30%,
      40: 40%,
      50: 50%,
      60: 60%,
      70: 70%,
      75: 75%,
      80: 80%,
      90: 90%,
      100: 100%,
      auto: auto
    )
  ),
  "my-min-vh": (
    property: min-height,
    class: my-min-vh,
    responsive: true,
    values: (
      0: 0,
      25: 25vh,
      30: 30vh,
      40: 40vh,
      50: 50vh,
      60: 60vh,
      70: 70vh,
      75: 75vh,
      80: 80vh,
      90: 90vh,
      100: 100vh,
      auto: auto
    )
  ),
  "my-min-h": (
    property: min-height,
    class: my-min-h,
    responsive: true,
    values: (
      0: 0,
      1: 1rem,
      2: 1.5rem,
      3: 2rem,
      4: 2.5rem,
      5: 3rem,
      6: 3.5rem,
      7: 4rem,
      8: 4.5rem,
      9: 5rem,
      10: 10rem,
      11: 11rem,
      12: 12rem,
      13: 13rem,
      14: 14rem,
      15: 15rem,
      16: 16rem,
      17: 17rem,
      18: 18rem,
      19: 19rem,
      20: 20rem,
      25: 25%,
      30: 30%,
      40: 40%,
      50: 50%,
      60: 60%,
      70: 70%,
      75: 75%,
      80: 80%,
      90: 90%,
      100: 100%,
      auto: auto
    )
  ),
  "margins": (
    responsive: true,
    property: margin,
    class: ms,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "margins-x": (
    responsive: true,
    property: margin-left margin-right,
    class: msx,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "margins-y": (
    responsive: true,
    property: margin-top margin-bottom,
    class: msy,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "margins-top": (
    responsive: true,
    property: margin-top,
    class: mst,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "margins-end": (
    responsive: true,
    property: margin-right,
    class: mse,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "margins-bottom": (
    responsive: true,
    property: margin-bottom,
    class: msb,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "margins-start": (
    responsive: true,
    property: margin-left,
    class: mss,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "paddings": (
    responsive: true,
    property: padding,
    class: ps,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "paddings-x": (
    responsive: true,
    property: padding-left padding-right,
    class: psx,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "paddings-y": (
    responsive: true,
    property: padding-top padding-bottom,
    class: psy,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "paddings-top": (
    responsive: true,
    property: padding-top,
    class: pst,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "paddings-end": (
    responsive: true,
    property: padding-right,
    class: pse,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "paddings-bottom": (
    responsive: true,
    property: padding-bottom,
    class: psb,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "paddings-start": (
    responsive: true,
    property: padding-left,
    class: pss,
    values: (
        6: 4rem,
        7: 5rem,
        8: 6rem,
        9: 7rem,
        10: 8rem,
    )
  ),
  "flex": (
    responsive: true,
    property: flex,
    values: (
      default: 0 0 auto,
      fill: 1 1 auto,
      all-auto: auto,
      none: none
      )
  )
);
