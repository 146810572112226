.bsb-animated {
    --bsb-animation-duration: 1s;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both
}

@keyframes bsb-fadeIn {
    0% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.bsb-fadeIn {
    animation-name: bsb-fadeIn
}

@keyframes bsb-fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.bsb-fadeInUp {
    animation-name: bsb-fadeInUp
}

@keyframes bsb-fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.bsb-fadeInDown {
    animation-name: bsb-fadeInDown
}

@keyframes bsb-fadeInLeft {
    0% {
        opacity: 0;
        transform: translate3d(-100%, 0, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.bsb-fadeInLeft {
    animation-name: bsb-fadeInLeft
}

@keyframes bsb-fadeInRight {
    0% {
        opacity: 0;
        transform: translate3d(100%, 0, 0)
    }
    to {
        opacity: 1;
        transform: translateZ(0)
    }
}

.bsb-fadeInRight {
    animation-name: bsb-fadeInRight
}

@keyframes bsb-fadeOut {
    0% {
        opacity: 1
    }
    to {
        opacity: 0
    }
}

.bsb-fadeOut {
    animation-name: bsb-fadeOut
}

@keyframes bsb-fadeOutUp {
    0% {
        opacity: 1
    }
    to {
        opacity: 0;
        transform: translate3d(0, -100%, 0)
    }
}

.bsb-fadeOutUp {
    animation-name: bsb-fadeOutUp
}

@keyframes bsb-fadeOutDown {
    0% {
        opacity: 1
    }
    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0)
    }
}

.bsb-fadeOutDown {
    animation-name: bsb-fadeOutDown
}

@keyframes bsb-fadeOutLeft {
    0% {
        opacity: 1
    }
    to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0)
    }
}

.bsb-fadeOutLeft {
    animation-name: bsb-fadeOutLeft
}

@keyframes bsb-fadeOutRight {
    0% {
        opacity: 1
    }
    to {
        opacity: 0;
        transform: translate3d(100%, 0, 0)
    }
}

.bsb-fadeOutRight {
    animation-name: bsb-fadeOutRight
}

@keyframes bsb-zoomIn {
    0% {
        opacity: 0;
        transform: scale3d(.3, .3, .3)
    }
    50% {
        opacity: 1
    }
}

.bsb-zoomIn {
    animation-name: bsb-zoomIn
}

.bsb-navbar:not(.bsb-navbar-caret) .dropdown-toggle:after {
    display: none
}

.bsb-navbar-hover .dropdown-menu.show.bsb-fadeIn {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeIn
}

.bsb-navbar-hover .dropdown-menu.show.bsb-fadeInUp {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInUp
}

.bsb-navbar-hover .dropdown-menu.show.bsb-fadeInDown {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInDown
}

.bsb-navbar-hover .dropdown-menu.show.bsb-fadeInLeft {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInLeft
}

.bsb-navbar-hover .dropdown-menu.show.bsb-fadeInRight {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInRight
}

.bsb-navbar-hover .dropdown-menu.show.bsb-zoomIn {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-zoomIn
}

@media(min-width:576px) {
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown>.dropdown-menu.show {
        display: none !important
    }
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu,
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show {
        display: block !important
    }
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeIn,
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeIn {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeIn
    }
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInUp,
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInUp {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInUp
    }
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInDown,
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInDown {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInDown
    }
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInLeft,
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInLeft {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInLeft
    }
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInRight,
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInRight {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInRight
    }
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-zoomIn,
    .bsb-navbar.navbar-expand-sm.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-zoomIn {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-zoomIn
    }
}

@media(min-width:768px) {
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown>.dropdown-menu.show {
        display: none !important
    }
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu,
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show {
        display: block !important
    }
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeIn,
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeIn {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeIn
    }
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInUp,
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInUp {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInUp
    }
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInDown,
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInDown {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInDown
    }
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInLeft,
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInLeft {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInLeft
    }
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInRight,
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInRight {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInRight
    }
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-zoomIn,
    .bsb-navbar.navbar-expand-md.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-zoomIn {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-zoomIn
    }
}

@media(min-width:992px) {
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown>.dropdown-menu.show {
        display: none !important
    }
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu,
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show {
        display: block !important
    }
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeIn,
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeIn {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeIn
    }
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInUp,
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInUp {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInUp
    }
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInDown,
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInDown {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInDown
    }
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInLeft,
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInLeft {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInLeft
    }
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInRight,
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInRight {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInRight
    }
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-zoomIn,
    .bsb-navbar.navbar-expand-lg.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-zoomIn {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-zoomIn
    }
}

@media(min-width:1200px) {
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown>.dropdown-menu.show {
        display: none !important
    }
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu,
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show {
        display: block !important
    }
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeIn,
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeIn {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeIn
    }
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInUp,
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInUp {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInUp
    }
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInDown,
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInDown {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInDown
    }
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInLeft,
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInLeft {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInLeft
    }
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInRight,
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInRight {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInRight
    }
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-zoomIn,
    .bsb-navbar.navbar-expand-xl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-zoomIn {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-zoomIn
    }
}

@media(min-width:1400px) {
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown>.dropdown-menu.show {
        display: none !important
    }
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu,
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show {
        display: block !important
    }
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeIn,
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeIn {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeIn
    }
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInUp,
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInUp {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInUp
    }
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInDown,
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInDown {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInDown
    }
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInLeft,
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInLeft {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInLeft
    }
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInRight,
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInRight {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-fadeInRight
    }
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-zoomIn,
    .bsb-navbar.navbar-expand-xxl.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-zoomIn {
        --bsb-animation-duration: 500ms;
        animation-duration: var(--bsb-animation-duration);
        animation-fill-mode: both;
        animation-name: bsb-zoomIn
    }
}

.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown>.dropdown-menu.show {
    display: none !important
}

.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu,
.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show {
    display: block !important
}

.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeIn,
.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeIn {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeIn
}

.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInUp,
.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInUp {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInUp
}

.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInDown,
.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInDown {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInDown
}

.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInLeft,
.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInLeft {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInLeft
}

.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-fadeInRight,
.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-fadeInRight {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInRight
}

.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.bsb-zoomIn,
.bsb-navbar.navbar-expand.bsb-navbar-hover .dropdown:hover>.dropdown-menu.show.bsb-zoomIn {
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-zoomIn
}



.mobile-slide-container .mobile-slide-body{
    background-color: white;
	display: none;
	visibility: hidden;
	opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeOutLeft
}
.mobile-slide-container .mobile-slide-body.show{
	display: block;
	visibility: visible;
	opacity: 1;
    --bsb-animation-duration: 500ms;
    animation-duration: var(--bsb-animation-duration);
    animation-fill-mode: both;
    animation-name: bsb-fadeInRight
}