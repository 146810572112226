@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: auto;
	}
}
html {
	direction: ltr;
	overflow-x: hidden;
	box-shadow: none !important;
	-webkit-font-smoothing: antialiased;
	background-color: #FFFFFF;
	scroll-behavior: auto !important;
}

body {
	background-color: #FFFFFF !important;
	color: #777;
	font-family: "Montserrat", sans-serif;
	font-size: 14px;
	line-height: 26px;
	margin: 0;
}
body a, body p, body span, body ul, body ul li, body i, body b, body h1, body h2, body h3, body h4, body h5, body h6, body figcaption {
	font-family: "Montserrat", sans-serif;
}
body a, body a:hover, body a:visited, body a:active, body a:focus{
	text-decoration: none;
}
.dropdown-toggle.btn::after, .dropdown-toggle.nav-link::after {
	margin-left: .5rem;
	content: "";
	border: none;
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/></svg>');
	background-repeat: no-repeat;
	background-position: center;
	width: 12px;
	height: 12px;
	display: inline-block;
	vertical-align: 0;
	background-size: 100%;  
	transition: all .1s ease;
}
.nav-item.dropdown:hover > .dropdown-toggle.btn::after,
 .nav-item.dropdown:hover > .dropdown-toggle.nav-link::after,
 .dropdown-toggle.nav-link.show.flags::after {
	transform: rotate(180deg);
	transition: all .1s ease;
}
.position-unset {
	position: unset!important;
}
.mega-sub .dropdown-item:hover, .mega-sub .dropdown-item:focus{
	background-color: transparent;
}
.navbar-toggler:focus {
	box-shadow: 0 0 0 0 !important;
}
.color-transtrack{
	color: #EF3434 !important;
}
.color-green-secondary {
	color: #009999!important;
}
.color-black-secondary {
	color: #212529!important;
}
.bg-transtrack{
	background-color: #EF3434 !important;
}
.bg-pink-1{
	background-color: #fbf6f3;
}
.bg-pink-2{
	background-color: #FCD6D6;
}
.bg-green-secondary {
	background-color: #009999!important;
}
.bg-black-secondary {
	background-color: #212529!important;
}
.bg-linear-red {
	background-image: linear-gradient(#D02636 , #9C0E74);
}
.border-transtrack{
	border-color: #EF3434 !important;
}
.border-transtrack-solid-1{
	border: 1px solid #EF3434 !important;
}
.hover-grey:hover{
	color: #b6b7b7 !important;
}
.hover-bg-grey:hover{
	background-color: #b6b7b7 !important;
}
.offcanvas.offcanvas-start {
	width: 80%!important;
}
.may-w-full{
	width: 100%!important;
}
.may-w-auto{
	width: auto!important;
}
.may-rounded-pill {
	border-radius: 50rem !important;
}
.may-rounded-none{
	border-radius: 0!important;
}
.may-hover-bg-none:hover{
	background-color: transparent !important;
}
.may-hover-bg-yellow-light:hover, .mega-parent .mega-li:hover{
	background-color: #FBF6F3 !important;
}
.my-after-none::after{
	display: none!important;
}
.my-after-block::after{
	display: block!important;
}
.my-after-inline-block::after{
	display: inline-block!important;
}
.my-after-flex::after{
	display: flex!important;
}
.my-after-inline-flex::after{
	display: inline-flex!important;
}
.ls-0{
	letter-spacing: 0rem!important;
}
.dropdown-menu.lang-mobile .dropdown-item:active {
	color: #FFFFFF;
	background-color: #EF3434;
}
.zindex-offcanvas{
	z-index: 1050;
}
.bg-pink-1{
	background-color: #fbf6f3;
}
.bg-footer{
	background-color: #fbf6f3;
	border-top-left-radius: 3rem;
	border-top-right-radius: 3rem;
}
.bg-yellow-pill-top{
	background-color: #fbf6f3;
	border-top-left-radius: 3rem;
	border-top-right-radius: 3rem;
}
.bg-yellow-pill-bottom{
	background-color: #fbf6f3;
	border-bottom-left-radius: 3rem;
	border-bottom-right-radius: 3rem;
}
.text-transform-none{
	text-transform: none!important;
}
.border-grey-dc{
	border-color: #DCDCDC!important;
}
.my-input:focus, .my-input:focus-visible{
	z-index: inherit!important;
	box-shadow: none!important;
}
.my-vh-25 {
	height: 25vh!important;
}
.my-vh-50 {
	height: 50vh!important;
}
.my-vh-75 {
	height: 75vh!important;
}
.my-vh-100 {
	height: 100vh!important;
}
.my-z-1{
	z-index: 1!important;
}
.my-z-2{
	z-index: 2!important;
}
.my-z-3{
	z-index: 3!important;
}
.my-z-4{
	z-index: 4!important;
}
.my-z-5{
	z-index: 5!important;
}
.fw-900 {
	font-weight: 900!important;
}
.carousel-bg {
	background-size: cover;
	background-position: center;
	animation-duration: 10s; 
	background-repeat: no-repeat;
}
.carousel-bg.animated {
	animation-duration: 10s;
	animation-fill-mode: both;
}
.carousel-bg-animation {
	-webkit-animation-name: carousel-bg-animation;
	animation-name: carousel-bg-animation;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	transform-origin: right top;
	animation-duration: 10s; 
}
.my-tabber .tab-link{
	color: black;
	border: 1px solid black;
	background-color: white;
}
.my-tabber .tab-link.active{
	color: white;
	border: 1px solid transparent;
	background-color: #EF3434;
}
.owl-carousel .owl-stage{
	display: flex;
	align-items: stretch;
}
.label-demo{
	background-color: white;
	border: 1px #DCDCDC solid;
	cursor: pointer;
	user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}
.checkbox-demo:checked + .label-demo{
	background-color: #FCD6D6;
	border: 1px #EF3434 solid;
}
.breadcrumb, .breadcrumb li, .breadcrumb li a, .breadcrumb-item + .breadcrumb-item::before, .breadcrumb .breadcrumb-item.active {
	color: white !important;
}
.navigation .page-link.active, .navigation .active > .page-link {
	background-color: #EF3434;
	border-color: #EF3434;
}
.navigation .page-link {
	color: #000000;
}
@-webkit-keyframes carousel-bg-animation {
  from {
    transform:scale(1.2)
  }
  to {
    transform:scale(1)
  }
}
@keyframes carousel-bg-animation {
  from {
    transform:scale(1.2)
  }
  to {
    transform:scale(1)
  }
}
.fw-500{
	font-weight: 500 !important;
}
.fw-600{
	font-weight: 600 !important;
}
@media (min-width: 576px) {
	.may-w-sm-auto{
		width: auto!important;
	}
	.may-rounded-sm-pill {
		border-radius: 50rem !important;
	}
	.may-rounded-sm-none {
		border-radius: 0 !important;
	}
	.may-hover-bg-sm-none:hover{
		background-color: transparent !important;
	}
	.may-hover-bg-sm-yellow-light:hover{
		background-color: #FBF6F3 !important;
	}
	.my-after-sm-none::after{
		display: none!important;
	}
	.my-after-sm-block::after{
		display: block!important;
	}
	.my-after-sm-inline-block::after{
		display: inline-block!important;
	}
	.my-after-sm-flex::after{
		display: flex!important;
	}
	.my-after-sm-inline-flex::after{
		display: inline-flex!important;
	}
	.bg-footer{
		border-top-left-radius: 3.5rem;
		border-top-right-radius: 3.5rem;
	}
	.bg-yellow-pill-top{
		border-top-left-radius: 3.5rem;
		border-top-right-radius: 3.5rem;
	}
	.bg-yellow-pill-bottom{
		border-bottom-left-radius: 3.5rem;
		border-bottom-right-radius: 3.5rem;
	}
	.my-vh-sm-25 {
		height: 25vh!important;
	}
	.my-vh-sm-50 {
		height: 50vh!important;
	}
	.my-vh-sm-75 {
		height: 75vh!important;
	}
	.my-vh-sm-100 {
		height: 100vh!important;
	}
}
@media (min-width: 768px) {
	.may-w-md-auto{
		width: auto!important;
	}
	.may-rounded-md-pill {
		border-radius: 50rem !important;
	}
	.may-rounded-md-none {
		border-radius: 0 !important;
	}
	.may-hover-bg-md-none:hover{
		background-color: transparent !important;
	}
	.may-hover-bg-md-yellow-light:hover{
		background-color: #FBF6F3 !important;
	}
	.my-after-md-none::after{
		display: none!important;
	}
	.my-after-md-block::after{
		display: block!important;
	}
	.my-after-md-inline-block::after{
		display: inline-block!important;
	}
	.my-after-md-flex::after{
		display: flex!important;
	}
	.my-after-md-inline-flex::after{
		display: inline-flex!important;
	}
	.bg-footer{
		border-top-left-radius: 4rem;
		border-top-right-radius: 4rem;
	}
	.bg-yellow-pill-top{
		border-top-left-radius: 4rem;
		border-top-right-radius: 4rem;
	}
	.bg-yellow-pill-bottom{
		border-bottom-left-radius: 4rem;
		border-bottom-right-radius: 4rem;
	}
	.my-vh-md-25 {
		height: 25vh!important;
	}
	.my-vh-md-50 {
		height: 50vh!important;
	}
	.my-vh-md-75 {
		height: 75vh!important;
	}
	.my-vh-md-100 {
		height: 100vh!important;
	}
}
@media (min-width: 992px) {
	.may-w-lg-auto{
		width: auto!important;
	}
	.may-rounded-lg-pill {
		border-radius: 50rem !important;
	}
	.may-rounded-lg-none {
		border-radius: 0 !important;
	}
	.may-hover-bg-lg-none:hover{
		background-color: transparent !important;
	}
	.may-hover-bg-lg-yellow-light:hover{
		background-color: #FBF6F3 !important;
	}
	.my-after-lg-none::after{
		display: none!important;
	}
	.my-after-lg-block::after{
		display: block!important;
	}
	.my-after-lg-inline-block::after{
		display: inline-block!important;
	}
	.my-after-lg-flex::after{
		display: flex!important;
	}
	.my-after-lg-inline-flex::after{
		display: inline-flex!important;
	}
	.bg-footer{
		border-top-left-radius: 4.5rem;
		border-top-right-radius: 4.5rem;
	}
	.bg-yellow-pill-top{
		border-top-left-radius: 4.5rem;
		border-top-right-radius: 4.5rem;
	}
	.bg-yellow-pill-bottom{
		border-bottom-left-radius: 4.5rem;
		border-bottom-right-radius: 4.5rem;
	}
	.my-vh-lg-25 {
		height: 25vh!important;
	}
	.my-vh-lg-50 {
		height: 50vh!important;
	}
	.my-vh-lg-75 {
		height: 75vh!important;
	}
	.my-vh-lg-100 {
		height: 100vh!important;
	}
}
@media (min-width: 1200px) {
	.may-w-xl-auto{
		width: auto!important;
	}
	.may-rounded-xl-pill {
		border-radius: 50rem !important;
	}
	.may-rounded-xl-none {
		border-radius: 0 !important;
	}
	.may-hover-bg-xl-none:hover{
		background-color: transparent !important;
	}
	.may-hover-bg-xl-yellow-light:hover{
		background-color: #FBF6F3 !important;
	}
	.my-after-xl-none::after{
		display: none!important;
	}
	.my-after-xl-block::after{
		display: block!important;
	}
	.my-after-xl-inline-block::after{
		display: inline-block!important;
	}
	.my-after-xl-flex::after{
		display: flex!important;
	}
	.my-after-xl-inline-flex::after{
		display: inline-flex!important;
	}
	.bg-footer{
		border-top-left-radius: 5rem;
		border-top-right-radius: 5rem;
	}
	.bg-yellow-pill-top{
		border-top-left-radius: 5rem;
		border-top-right-radius: 5rem;
	}
	.bg-yellow-pill-bottom{
		border-bottom-left-radius: 5rem;
		border-bottom-right-radius: 5rem;
	}
	.my-vh-xl-25 {
		height: 25vh!important;
	}
	.my-vh-xl-50 {
		height: 50vh!important;
	}
	.my-vh-xl-75 {
		height: 75vh!important;
	}
	.my-vh-xl-100 {
		height: 100vh!important;
	}
}
@media (min-width: 1400px) {
	.may-w-xxl-auto{
		width: auto!important;
	}
	.may-rounded-xxl-pill {
		border-radius: 50rem !important;
	}
	.may-rounded-xxl-none {
		border-radius: 0 !important;
	}
	.may-hover-bg-xxl-none:hover{
		background-color: transparent !important;
	}
	.may-hover-bg-xxl-yellow-light:hover{
		background-color: #FBF6F3 !important;
	}
	.my-after-xxl-none::after{
		display: none!important;
	}
	.my-after-xxl-block::after{
		display: block!important;
	}
	.my-after-xxl-inline-block::after{
		display: inline-block!important;
	}
	.my-after-xxl-flex::after{
		display: flex!important;
	}
	.my-after-xxl-inline-flex::after{
		display: inline-flex!important;
	}
	.bg-footer{
		border-top-left-radius: 5.5rem;
		border-top-right-radius: 5.5rem;
	}
	.bg-yellow-pill-top{
		border-top-left-radius: 5.5rem;
		border-top-right-radius: 5.5rem;
	}
	.bg-yellow-pill-bottom{
		border-bottom-left-radius: 5.5rem;
		border-bottom-right-radius: 5.5rem;
	}
	.my-vh-xxl-25 {
		height: 25vh!important;
	}
	.my-vh-xxl-50 {
		height: 50vh!important;
	}
	.my-vh-xxl-75 {
		height: 75vh!important;
	}
	.my-vh-xxl-100 {
		height: 100vh!important;
	}
}